import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import QRCode from "react-qr-code";
import Modal from "simple-react-modal";

import Navbar from "../navbar/navbar";

import logo from "../../assets/images/blue-rectangle-logo.png";

import "./style.css";

function hashCode(str) {
  var hash = 0,
    i = 0,
    len = str.length;
  while (i < len) {
    hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
  }
  return hash;
}

function hashCodePositive(str) {
  return hashCode(str) + 2147483647 + 1;
}

function colorFromHash(hash) {
  let hashMod = Math.abs(hash % 6);

  switch (hashMod) {
    case 0:
      return "#0071EE";
    case 1:
      return "#FF7272";
    case 2:
      return "#FFB872";
    case 3:
      return "#70C808";
    case 4:
      return "#AE4CCC";
    default:
      return "#74B6FF";
  }
}

function whiteColorFromHash(hash) {
  let hashMod = Math.abs(hash % 6);

  switch (hashMod) {
    case 0:
      return "#71A4DB";
    case 1:
      return "#E3A4A4";
    case 2:
      return "#E3C4A4";
    case 3:
      return "#A2CA75";
    case 4:
      return "#BF93CC";
    default:
      return "#A5C2E3";
  }
}

function isPrivateURL(pathname) {
  return pathname.includes("/i/");
}

function PrivateProfileRender(props) {
  const { data, loading } = props;

  const loadingContent = <div className="group-name">Loading...</div>;

  return (
    <div className="PrivateGroup">
      <img className="avatar" src="/private_avatar.png" alt="Avatar" />
      {loading ? (
        loadingContent
      ) : (
        <>
          <div className="text-container">
            <div className="group-name">
              {data?.private_link_channel?.metadata?.name}
            </div>
          </div>
          <a
            className="join-group-button"
            href="https://apps.apple.com/us/app/vama/id1600580466"
          >
            Join Group
          </a>
          <div className="group-description">
            If you have <strong>Vama</strong>, you can join{" "}
            <strong>{data?.private_link_channel?.metadata?.name}</strong> right
            away.
          </div>
        </>
      )}
    </div>
  );
}

function TempRedirect(props) {
  let { link } = useParams();
  const { pathname } = useLocation();
  const isPrivate = isPrivateURL(pathname);

  // const appStoreLink = "https://apps.apple.com/us/app/vama/id1600580466";
  const [linkData, setLinkData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dynamicLink, setDynamicLink] = useState("");
  const [showModal, setShowModal] = useState(false);

  let avatarProps = {};
  let rowProps = {};
  let usernameProps = {};
  let bioProps = {};
  let backgroundProps = {};

  if (isMobile) {
    rowProps["--row-width"] = "78%";
    bioProps["--bio-width"] = "78%";
  }

  if (linkData && linkData.account && linkData.account.first_name) {
    avatarProps["--color"] = colorFromHash(
      hashCodePositive(linkData.account.first_name + linkData.account.last_name)
    );
    avatarProps["--white-color"] = whiteColorFromHash(
      hashCodePositive(linkData.account.first_name + linkData.account.last_name)
    );

    rowProps["--row-background"] = "#0071EE";
    rowProps["--row-text-color"] = "#FFFFFF";
    rowProps["cursor"] = "pointer";
    usernameProps["--username-text-color"] = "#000000";
    bioProps["--bio-text-color"] = "#8e8e93";
    backgroundProps["--top-gradient"] = "#FFFFFF";
    backgroundProps["--bottom-gradient"] = "#FFFFFF";
  }

  const centerButtonStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };

  const fetchLinkData = (linkArg) => {
    const url = `/public_link?suffix=${isPrivate ? "i/" : ""}${linkArg}`;
    setLoading(false);
    setLoading(true);
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    axios.get(url).then(
      (res) => {
        if (res.status === 200) {
          if (res.data.error) {
            setLinkData({});
            setLoading(false);
            setDynamicLink("");
            window.location.href = "https://vama.com";
          } else {
            setLinkData(res.data.data);
            setLoading(false);
          }

          // ONLY WORKS FOR PRODUCTION - QUICK PATCH DONE
          setDynamicLink("https://vama.me/" + link);
        }
      },
      (err) => {
        setLinkData({});
        setLoading(false);
        setDynamicLink("");
        window.location.href = "https://vama.com";
      }
    );
  };

  useEffect(() => {
    if (link) {
      fetchLinkData(link);
    }
  }, [link]);

  function userProfileAvatar(account) {
    if (!account.avatar) {
      return (
        <div className="row-container">
          <div className="avatar-space">
            <div className="avatar-initials-image" style={avatarProps}>
              {account.first_name.toUpperCase().charAt(0) +
                account.last_name.toUpperCase().charAt(0)}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row-container">
          <div className="avatar-space">
            <div className="avatar-initials-image" style={avatarProps}>
              <img className="cover" src={account.avatar} alt="User Avatar" />
            </div>
          </div>
        </div>
      );
    }
  }

  function userBioPage(linkData) {
    const account = linkData.account;

    return (
      <div>
        {!loading && account.id ? (
          <div>
            {userProfileAvatar(account)}
            <div className="groupName">
              {account.first_name} {account.last_name}
            </div>
            <div className="groupLink" style={usernameProps}>
              @{link.toLowerCase()}
            </div>
            <div className="groupDescription" style={bioProps}>
              {account.textContent}
            </div>

            {!isMobile && (
              <>
                <a
                  href="https://apps.apple.com/us/app/vama/id1600580466"
                  target="_blank"
                  style={centerButtonStyles}
                  rel="noreferrer"
                >
                  <div className="joinGroupButton" arid-hidden="true">
                    View In App Store
                  </div>
                </a>
                <span style={centerButtonStyles} onClick={openQRModal}>
                  <div className="download-on-my-phone">
                    Download on my phone
                  </div>
                </span>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  // function benefit(benefitDescription) {
  //   if (benefitDescription) {
  //     return (
  //       <div className="externalInfo">
  //         <i className="fa-solid fa-check"></i>
  //         <span className="externalInfoContent">{benefitDescription}</span>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  function getAvatarProps(user) {
    let result = {};
    result["--color"] = colorFromHash(
      hashCodePositive(user.first_name + user.last_name)
    );
    result["--white-color"] = whiteColorFromHash(
      hashCodePositive(user.first_name + user.last_name)
    );

    return result;
  }

  function groupCreatedBy(channel) {
    const host0 = channel.hosts[0];
    const admins = channel.admins ? channel.admins : [];
    const list = [...[host0], ...admins];

    const containerMargins = {
      marginBottom: "24px",
    };
    // const imageMargins = {
    //   marginLeft: "10px",
    //   marginRight: "6px",
    // };
    if (!host0.username) {
      return <div></div>;
    }
    return (
      <div className="createdByContainer" style={containerMargins}>
        <div className="createdByGoat">Hosted by</div>

        <div className="createdByUsers">
          {list.map((user, key) => (
            <div className="createdByUser" key={key}>
              <a href={dynamicLink}>
                {!user.avatar ? (
                  <div
                    className="created-by-goat-initials-avatar"
                    data-title={
                      user.first_name.toUpperCase().charAt(0) +
                      user.last_name.toUpperCase().charAt(0)
                    }
                    style={getAvatarProps(user)}
                  />
                ) : (
                  <img
                    className="createdByGoatAvatar"
                    src={user.avatar}
                    alt="Goat Avatar"
                  />
                )}
              </a>
              <a href={dynamicLink}>
                <div className="createdByGoatName">
                  <div>{user.first_name}</div>
                  <div>{user.last_name}</div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function groupPage(groupData) {
    // let benefitWidth;
    const groupAvatarProps = {};
    if (isMobile) {
      groupAvatarProps["--size"] = "30px";
      // benefitWidth = "82%";
    }

    let groupDataName = groupData.metadata.name;
    let fName = groupDataName.split(" ")[0] ? groupDataName.split(" ")[0] : "";
    let lName = groupDataName.split(" ")[1] ? groupDataName.split(" ")[1] : "";

    avatarProps["--color"] = colorFromHash(hashCodePositive(fName + lName));
    avatarProps["--white-color"] = whiteColorFromHash(
      hashCodePositive(fName + lName)
    );

    return (
      <div>
        {!loading && groupData ? (
          <div>
            {!groupData.avatar ||
            groupData.avatar.length === 0 ||
            groupData.avatar.startsWith(
              "https://static.sendbird.com/sample/cover"
            ) ? (
              <div className="row-container">
                <div className="avatar-space">
                  <div className="avatar-initials-image" style={avatarProps}>
                    {groupData.metadata.name.toUpperCase().charAt(0)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row-container">
                <div className="avatar-space">
                  <div className="group-avatar-image" style={groupAvatarProps}>
                    <img
                      className="group-cover"
                      src={groupData.avatar}
                      alt="Group avatar"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="groupName">{groupData.metadata.name}</div>
            <div className="groupLink">@{link.toLowerCase()}</div>
            <div className="subscribers">
              {groupData.member_count ? groupData.member_count : 0}{" "}
              {groupData.member_count
                ? groupData.member_count > 1
                  ? "members"
                  : "member"
                : "member"}
            </div>
            {groupData.metadata.public.description !== undefined ? (
              <div className="groupDescription" style={bioProps}>
                {groupData.metadata.public.description}
              </div>
            ) : (
              <div></div>
            )}

            {
              // TODO: DAVID COME BACK TO THIS, MAEK AN ARRAY
              /* {
              groupData.metadata.benefit1 || groupData.metadata.benefit2 || groupData.metadata.benefit3 ? (
                <div className="externalDescription">
                  {benefit(groupData.metadata.benefit1)}
                  {benefit(groupData.metadata.benefit2)}
                  {benefit(groupData.metadata.benefit3)}
                </div>
              ) : (
                null
              )
            } */
            }

            {groupCreatedBy(groupData)}

            {!isMobile && (
              <>
                <a
                  href="https://apps.apple.com/us/app/vama/id1600580466"
                  target="_blank"
                  style={centerButtonStyles}
                  rel="noreferrer"
                >
                  <div className="joinGroupButton" arid-hidden="true">
                    View In App Store
                  </div>
                </a>
                <span style={centerButtonStyles} onClick={openQRModal}>
                  <div className="download-on-my-phone">
                    Download on my phone
                  </div>
                </span>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  const openQRModal = () => {
    setShowModal(true);
  };

  const closeQRModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {!isMobile && <Navbar buttonText="VIEW IN APP STORE" />}
      <div className="theme-background" style={backgroundProps}>
        {isPrivate && !loading ? (
          <PrivateProfileRender data={linkData} loading={loading} />
        ) : (
          <div
            className={`border-round-object ${
              isMobile ? "is-mobile-view" : ""
            }`}
          >
            {linkData.account ? (
              userBioPage(linkData)
            ) : linkData.public_link_channel ? (
              groupPage(linkData.public_link_channel)
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>

      {!loading && isMobile && isPrivate && (
        <div className="temp-redirect-footer">
          <div className="temp-redirect-footer-content">
            <Link to="/">
              <img src={logo} alt="Vama" />
            </Link>

            <a href="https://apps.apple.com/us/app/vama/id1600580466">
              <div
                className="joinGroupButton"
                arid-hidden="true"
                style={rowProps}
              >
                {linkData.account ? "Send message" : "Join This Group"}
              </div>
            </a>
          </div>
        </div>
      )}

      {!isMobile && (
        <Modal show={showModal} onClose={closeQRModal}>
          <div className="modal-header">
            <h3 style={{ margin: 20 }}>Download on the App Store</h3>
            <i className="fa-solid fa-close" onClick={closeQRModal}></i>
          </div>
          <div className="qr-code-modal-image">
            <QRCode value="https://apps.apple.com/us/app/vama/id1600580466" />
          </div>
        </Modal>
      )}
    </>
  );
}

export default TempRedirect;
